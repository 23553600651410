import React, { useEffect, useRef, useState } from "react";
import "../Assets/Styles/AboutUs.css";
import data from "../Common/Data.json";
import about1 from "../Assets/Images/about1.png";
import about3 from "../Assets/Images/about3.png";
import aboutslider1 from "../Assets/Images/aboutslider1.JPG";
import aboutslider2 from "../Assets/Images/aboutslider2.JPG";
import aboutslider3 from "../Assets/Images/aboutslider3.JPG";
import aboutslider4 from "../Assets/Images/aboutslider4.JPG";
import aboutslider5 from "../Assets/Images/aboutslider5.JPG";
import aboutslider6 from "../Assets/Images/aboutslider6.jpg";
import aboutslider7 from "../Assets/Images/aboutslider7.jpg";
import aboutslider8 from "../Assets/Images/aboutslider8.jpg";
import aboutslider9 from "../Assets/Images/aboutslider9.JPG";
import aboutslider10 from "../Assets/Images/aboutslider10.jpg";
import { Accordion } from "react-bootstrap";

const AboutUs = () => {
  const [index, setIndex] = useState(0);
  const slidesRef = useRef([]);
  const dotsRef = useRef([]);

  const changeSlide = () => {
    for (let i = 0; i < slidesRef.current.length; i++) {
      const currentSlide = slidesRef.current[i];
      const currentDot = dotsRef.current[i];

      if (currentSlide && currentDot) {
        currentSlide.style.display = "none";
        currentDot.classList.remove("active");
      }
    }

    const nextIndex = (index + 1) % slidesRef.current.length;

    const activeSlide = slidesRef.current[nextIndex];
    const activeDot = dotsRef.current[nextIndex];

    if (activeSlide && activeDot) {
      activeSlide.style.display = "block";
      activeDot.classList.add("active");
    }

    setIndex(nextIndex);
  };

  useEffect(() => {
    const interval = setTimeout(changeSlide, 2000);

    return () => clearTimeout(interval);
  }, [index]);

  // Inside the functional component
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the breakpoint as needed

  // Update the state when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="first-about-container">
        <h2>{data.organization.chiefFunctionaryMessage.title}</h2>
        <p>{data.organization.chiefFunctionaryMessage.message}</p>
      </div>
      <div className="second-about-container">
        <img src={about1} alt="" />
        <div className="second-about-description">
        {isMobile ? (
          <Accordion defaultActiveKey="null">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h2>{data.organization.founderDetails.name}</h2>
              </Accordion.Header>
              <Accordion.Body>
                {data.organization.founderDetails.bio}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <>
            <h2>{data.organization.founderDetails.name}</h2>
            <p>{data.organization.founderDetails.bio}</p>
          </>
        )}
        </div>
      </div>
      <div className="third-about-poster-slider">
        <div id="about-slider">
          <div ref={(el) => (slidesRef.current[0] = el)} className="slides">
            <img src={aboutslider1} className="main-images" width="100%" alt="" />
          </div>

          <div ref={(el) => (slidesRef.current[1] = el)} className="slides">
            <img src={aboutslider2} className="main-images" width="100%" alt="" />
          </div>

          <div ref={(el) => (slidesRef.current[2] = el)} className="slides">
            <img src={aboutslider3} className="main-images" width="100%" alt="" />
          </div>

          <div ref={(el) => (slidesRef.current[3] = el)} className="slides">
            <img src={aboutslider4} className="main-images" width="100%" alt="" />
          </div>

          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider5} className="main-images" width="100%" alt="" />
          </div>
          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider6} className="main-images" width="100%" alt="" />
          </div>
          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider7} className="main-images" width="100%" alt="" />
          </div>
          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider8} className="main-images" width="100%" alt="" />
          </div>
          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider9} className="main-images" width="100%" alt="" />
          </div>
          <div ref={(el) => (slidesRef.current[4] = el)} className="slides">
            <img src={aboutslider10} className="main-images" width="100%" alt="" />
          </div>

          <div id="dot">
            <span
              ref={(el) => (dotsRef.current[0] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[1] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[2] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[3] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[4] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[5] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[1] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[2] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[3] = el)}
              className="dot"
            ></span>
            <span
              ref={(el) => (dotsRef.current[4] = el)}
              className="dot"
            ></span>
          </div>
        </div>
      </div>
      <div className="third-about-container">
        <div className="third-about-description">
        {isMobile ? (
          <Accordion defaultActiveKey="null">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              <h2>{data.organization.voiceOfFounder.title}</h2>
              </Accordion.Header>
              <Accordion.Body>
              {data.organization.voiceOfFounder.message}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <>
          <h2>{data.organization.voiceOfFounder.title}</h2>
          <p>{data.organization.voiceOfFounder.message}</p>
          </>
        )}
        </div>
        <img src={about3} alt="" />
      </div>
    </>
  );
};

export default AboutUs;
