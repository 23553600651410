import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../Assets/Styles/Header.css";
import logo from "../Assets/Images/Crescent-removebg-preview 1.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav>
        <input type="checkbox" id="menuToggle" checked={isMenuOpen} />
        <label htmlFor="menuToggle" className="menu-btn" onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </label>
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="" />
            <h4 className="logo-title">The Crescent Foundation</h4>
          </div>
        </Link>
        <ul className={isMenuOpen ? "menu-list open" : "menu-list"}>
          <li>
            <Link to="/" onClick={handleMenuToggle}>
              About
            </Link>
          </li>
          <li className="dropdown">
            <Link to="/">Our Work</Link>
            <ul className="dropdown-content">
              <li>
                <Link to="/blood-Center" onClick={handleMenuToggle}>
                  The Crescent blood Centre
                </Link>
              </li>
              <li>
                <Link to="/Tcf-vocational-training-institute" onClick={handleMenuToggle}>
                  Tcf vocational training institute
                </Link>
              </li>
              <li>
                <Link to="/crescent-public-school" onClick={handleMenuToggle}>
                  Crescent Public School
                </Link>
              </li>
              <li>
                <Link to="/crescent-clothing-center" onClick={handleMenuToggle}>
                  The Crescent Clothings
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/gallery" onClick={handleMenuToggle}>
              Gallery
            </Link>
          </li>
          <li>
            <Link to="/donate" onClick={handleMenuToggle}>
              Donate
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
