import React, { useState, useEffect } from "react";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import organizationData from "../Common/Data.json";
import "../Assets/Styles/Description.css";
import HoverCards from "./HoverCards";
import msiddi from "../Assets/Images/msiddi.png";
import { Link } from "react-router-dom";

const Description = () => {
  const [accordionOpen, setAccordionOpen] = useState(null);
  const [displayedData, setDisplayedData] = useState([]);
  const handleAccordionClick = (index) => {
    setAccordionOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleWindowResize = () => {
      // Close accordion when switching to desktop view
      if (window.innerWidth > 768) {
        setAccordionOpen(null);
      }
    };

    // Attach the event listener
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    // Display only the first three items
    setDisplayedData(Object.values(organizationData.organization).slice(0, 3));
  }, []);

  return (
    <>
    <div className="main-container">
      {displayedData.map((value, index) => (
        <div
          key={index}
          className={`accordion-item ${accordionOpen === index ? "open" : ""}`}
        >
          <h2
            className="accordion-title"
            onClick={() => handleAccordionClick(index)}
          >
            <span className="arrows">
              {value.title1}{" "}
              {(accordionOpen === index) ? (
                <IoIosArrowDropupCircle className="arrow" />
              ) : (
                <IoIosArrowDropdownCircle className="arrow" />
              )}
            </span>
          </h2>
          {accordionOpen === index && (
            <p className="main-desc">{value.description1}</p>
          )}
        </div>
      ))}
    </div>

    <h2 className="main-title">OUR PROGRAMS</h2>
    <HoverCards />

    <h2 className="main-title">OUR BOARD</h2>
    <div className="single-card">
      <img className="card-image" src={msiddi} alt="" />
      <div className="card-content">
        <h2>Director Message </h2>
        <p>
          “One who is not grateful to mankind is not grateful to Almighty” The
          Crescent foundation is initiated in 2008 by the Mohammed Siddique
          founded single-handedly with the mission to work for the betterment
          and upliftment of underprivileged societies in the education and
          health and skill development sector.
        </p>
        <h3>
          Mohammed Siddique <span>Chief Functionary </span>
        </h3>
        <Link to="/About">
          <button>More Info</button>
        </Link>
      </div>
    </div>
  </>
  );
};

export default Description;
